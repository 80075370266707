
import buyAddressApi from "@/common/api/business";
import {Component, Vue } from "vue-property-decorator";
import {Util} from "@/common/util";
@Component({})
export default class MergePools extends Vue{
  private visible:boolean = false
  private currentOrderId:string = ''
  private buyAddressApi: any = new buyAddressApi();
  private usePoolsList:any =  []
  private usePool:any = {
    id:'',
    name:''
  }
  private mergeOrder:any ={}
  private show(pool_id: string,orderInfo:any):void {
      this.usePool = {
      id:'',
      name:''
    }
    this.getUsePoolList(pool_id)
    this.currentOrderId = orderInfo.id
    this.visible = true
  }
  private getUsePoolList(pool_id:string):void {
    this.buyAddressApi.usePools({pool_id: pool_id,},
        (res: any) => {
          console.log('使用中桌子',res.data.list)
          this.usePoolsList = res.data.list
        }
    );
  }
  private async getUsePoolOrderId(pool_id:string){
    return new Promise(resolve => {
      this.buyAddressApi.orderindex({pool_id},((res:any)=>{
        console.log('使用球桌订单信息',res)
        let obj = res.data.processing || {}
        resolve(obj as any)
      }))
    })
  }
  private async onSelectPools(value:any){
    console.log('value',value)
    const result = this.usePoolsList.find((item:any)=>item.id===value)
    this.usePool.name = result.name
    console.log('查找结果',result)
    const obj = await this.getUsePoolOrderId(value)
    console.log('obj',obj)
    this.mergeOrder = obj
  }
  onConfirm() {
    this.$confirm(`是否并台到${this.usePool.name}？并台之后需要一起结账`, "并台提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.buyAddressApi.mergePoolOrder({
        order_id:this.currentOrderId,
        bind_order_id:this.mergeOrder.id
      },((res:any)=>{
        Util.showToast("并台成功");
        this.$emit('success')
        this.visible = false
      }))
    })
  }
}

