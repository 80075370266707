






















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class RecommandsList extends Vue {
  // @Prop({ type: [String, Number], required: true }) readonly child!: string | number
  @Prop() tableData!: any;
}
