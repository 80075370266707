
import buyAddressApi from "@/common/api/business";
import {Component, Vue } from "vue-property-decorator";
import {Util} from "@/common/util";
@Component({})
export default class CancelMergePools extends Vue{
  private visible:boolean = false
  private buyAddressApi: any = new buyAddressApi();
  private tableData:any  = []
  private orderInfo:any = {}
  private tablePage:any =  {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  }
  private show(pool_id: string,orderInfo:any):void {
    console.log('订单信息',orderInfo)
    this.orderInfo = orderInfo
    this.visible = true
    this.getTableList()
  }
  private getTableList():void {
    const order_id = this.orderInfo.id
    this.buyAddressApi.mergePoolList({order_id: order_id,},
        (res: any) => {
          console.log('并台列表',res)
          this.tableData = res.data.list
        }
    );
  }

  onClickCancel(row:any) {
    this.$confirm(`确认撤销${row.pool_name}吗？`, "撤销提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.buyAddressApi.cancelMergeOrder({
        order_group_id:row.id
      },((res:any)=>{
        Util.showToast("撤销成功");
        this.$emit('success')
        this.getTableList()
      }))
    })
  }
}

