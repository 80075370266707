



















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import buyAddressApi from "@/common/api/business";
import { Util } from "@/common/util";
@Component
export default class RecommandsList extends Vue {
  private buyAddressApi: any = new buyAddressApi();

  // @Prop({ type: [String, Number], required: true }) readonly child!: string | number
  @Prop() tableData!: any;
  private cancelpay(id: string): void {
    this.buyAddressApi.cancelpay(
      {
        order_id: id,
      },
      () => {
        this.$emit("orderindex");
        Util.showToast("取消成功");
      }
    );
  }
}
