














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util } from "@/common/util";
import buyAddressApi from "@/common/api/business";
@Component
export default class RecommandsList extends Vue {
  // @Prop({ type: [String, Number], required: true }) readonly child!: string | number
  @Prop() tableData!: any;
  private buyAddressApi: any = new buyAddressApi();
  private assistantover(id: string): void {
    this.buyAddressApi.assistantover(
      {
        assistant_order_id: id,
      },
      () => {
        this.$emit("overindex");
        Util.showToast("结算成功");
      }
    );
  }
}
